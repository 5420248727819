@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    --font-family: "Roboto Slab", sans-serif;
  }
}
:root{
  --primary-color: purple;
  --secondary-color: blue;
}
body {
  overflow: overlay; /* For scrollbar from https://stackoverflow.com/questions/23200639/transparent-scrollbar-with-css */

}

#particles canvas {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* width */
::-webkit-scrollbar * {
  width: 10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181818;
  border-radius: 12px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}